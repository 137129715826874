import React, { useEffect, useState } from 'react';
import config from '../../../coreFIles/config';
import Pancakeswap from '../images/partner/pancakeswap.png';
import Certhik from '../images/partner/certhik.png';
import Coinstore from '../images/partner/coinstore.png';
import Coingenko from '../images/partner/coingenko.png';
import "../css/style.css"
import "../css/responsive.css"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import {Autoplay} from 'swiper';


const Inthenewsslider = () => {


    return (

        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}

                breakpoints={{
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 8,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                   <a href="https://coinpedia.org/guest-post/top-5-coins-to-watch-out-for-in-2022/" target="_blank">
                    <img src='images/cp-logo.webp' />
                    </a> 
                </SwiperSlide>
                <SwiperSlide>
                   <a href="https://www.bloomberg.com/press-releases/2022-11-17/mintforce-mr-mint-s-freshly-launched-nft-collection-transforms-mining-on-the-web" target="_blank">
                    <img src='images/bloomberg.png' />
                    </a> 
                </SwiperSlide>
                <SwiperSlide>
                   <a href="https://finance.yahoo.com/news/mr-mint-token-launches-token-193000141.html" target="_blank" >
                    
                    <img src='images/yahoo-logo.png' />
                    </a> 
                </SwiperSlide>
                <SwiperSlide>
                   <a href="https://u.today/press-releases/mr-mint-mnt-public-sale-goes-live-on-july-1st-2022" target="_blank">
                    
                    <img src='images/u-today.png' />
                    </a> 
                </SwiperSlide>
                <SwiperSlide>
                  <a href="https://zycrypto.com/a-six-month-public-sale-begins-for-mr-mints-mnt-token/" target="_blank" >
                     <img src='images/zycrypto.png' />
                    </a> 
                </SwiperSlide>
                <SwiperSlide>
                   <a href="https://cryptoslate.com/press-releases/why-mnt-is-worth-investing-in-nfts-metaverse-p2e-and-much-more" target="_blank">
                    <img src='images/c-icon-cryptoslate.png' />
                    </a> 
                </SwiperSlide>
                <SwiperSlide>
                   <a href="https://coinpedia.org/guest-post/top-5-coins-to-watch-out-for-in-2022/" target="_blank">
                    <img src='images/cp-logo.webp' />
                    </a> 
                </SwiperSlide>
                <SwiperSlide>
                   <a href="https://www.bloomberg.com/press-releases/2022-11-17/mintforce-mr-mint-s-freshly-launched-nft-collection-transforms-mining-on-the-web" target="_blank">
                    <img src='images/bloomberg.png' />
                    </a> 
                </SwiperSlide>
                <SwiperSlide>
                   <a href="https://finance.yahoo.com/news/mr-mint-token-launches-token-193000141.html" target="_blank" >
                    
                    <img src='images/yahoo-logo.png' />
                    </a> 
                </SwiperSlide>
                <SwiperSlide>
                   <a href="https://u.today/press-releases/mr-mint-mnt-public-sale-goes-live-on-july-1st-2022" target="_blank">
                    
                    <img src='images/u-today.png' />
                    </a> 
                </SwiperSlide>
                <SwiperSlide>
                  <a href="https://zycrypto.com/a-six-month-public-sale-begins-for-mr-mints-mnt-token/" target="_blank" >
                     <img src='images/zycrypto.png' />
                    </a> 
                </SwiperSlide>
                <SwiperSlide>
                   <a href="https://cryptoslate.com/press-releases/why-mnt-is-worth-investing-in-nfts-metaverse-p2e-and-much-more" target="_blank">
                    <img src='images/c-icon-cryptoslate.png' />
                    </a> 
                </SwiperSlide>
            </Swiper>
        </>
    )

}
export default Inthenewsslider;