import React, { useEffect, useState } from "react";
import config from "../../../coreFIles/config";
import Cookies from "js-cookie";
import $ from "jquery";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../css/header.css";
import { Link } from "react-router-dom";
// asdf
const Header = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the page has scrolled beyond a certain distance
      const offset = window.scrollY;
      if (offset > 100) { // Adjust this value as needed
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const loginData = !Cookies.get("loginSuccessMrMint")
    ? []
    : JSON.parse(Cookies.get("loginSuccessMrMint"));

  const navigate = () => {
    if (loginData.email) {
      window.location.href = "dashboard";
    }
    else {
      window.location.href = "login";
    }
  }

  const handleClose = function () {
    const backgrnd = document.getElementsByClassName("btn-close")[0]
    backgrnd.click()
  }

  return (
    <>
      <header className={`sticky-header ${isSticky ? 'is-sticky' : ''}`}>
        {["xl"].map((expand) => (
          <Navbar key={expand} expand={expand} className="align-items-center" >
            <Container>
              <Navbar.Brand href="#" className="text-white">
                <img
                  className="atom"
                  // src="assets/images/logo.png"
                  src='images/logo.svg'
                  width="150px"
                  alt="mrmint logo"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    <img
                      className="atom"
                      src="assets/images/logo.png"
                      width="125px"
                      alt="mrmint logo"
                    />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-center flex-grow-1 pe-3">
                    {/* <Nav.Link href="#">Home</Nav.Link> */}

                    <NavDropdown
                      title="Products"
                      id={`offcanvasNavbarDropdown-expand-${expand}`}
                    >
                      <NavDropdown.Item href="https://stepmint.io/" target="_blank">Stepmint</NavDropdown.Item>
                      <NavDropdown.Item href="https://nft.mrmint.io/" target="_blank">
                        MintForce
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://presale.sportsmint.io/" target="_blank">
                        Presale
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://sportsmint.io/" target="_blank">
                        Sportsmint
                      </NavDropdown.Item>

                    </NavDropdown>
                    <Nav.Link href="#ecosystem" onClick={() => handleClose()}>Ecosystem</Nav.Link>
                    {/* <Nav.Link href="#aboutus">About Us</Nav.Link> */}
                    <Nav.Link href="#roadmap" onClick={() => handleClose()}>Roadmap</Nav.Link>
                    <Nav.Link href="#tokenomics" onClick={() => handleClose()}>Tokenomics</Nav.Link>
                    <Nav.Link href="#event" onClick={() => handleClose()}>Events</Nav.Link>
                    <Nav.Link href="#faq" onClick={() => handleClose()}>Faq</Nav.Link>
                    <Nav.Link href={`${config.baseUrl}Blog`} target="_blank">Blogs</Nav.Link>
                  </Nav>
                  <Form className="d-flex align-items-center">
                    <a href="https://www.coinstore.com/#/spot/MNTUSDT?ts=1712937732921" target="_blank">
                      <Button
                        variant="primary"
                        style={{ background: "linear-gradient(80deg, rgb(198 78 255 / 75%), rgb(0 205 255 / 83%))" }}
                      >
                        Buy MNT
                      </Button>
                    </a>

                    <Button
                      variant="primary"
                      className="ms-2"
                      onClick={navigate}
                      style={{ background: "linear-gradient(80deg, rgb(198 78 255 / 75%), rgb(0 205 255 / 83%))" }}
                    >
                      {loginData.email ? "Dashboard" : "Login"}
                    </Button>
                  </Form>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>

        ))}
      </header>
    </>
  );
};
export default Header;
