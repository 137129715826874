import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Tab,
  Nav,
  Accordion,
} from "react-bootstrap";
import config from "../../../coreFIles/config";
import F1 from "../images/gallery/F1.png";

import "../css/style.css";
import "../css/responsive.css";
import { Zoom } from "react-reveal";

const UpcomingEvent = (props) => {

  console.log("props", props);
  if (Array.isArray(props.getEvent)) {
    var secondData = props.getEvent.filter((item) => item.eventType == 1)
    console.log(secondData, 'firstData');
  } else {
    console.error('props.getEvent is not an array:', props.getEvent);
  }

  return (
    <>
      <Row>
        {secondData?.map((value, index) => {
          return <Col md={4} className="mb-4" key={index}>
            <Zoom>
              <img src={`${config.imageUrl + value.image}`} alt={value?.altText} className="img-fluid" />
            </Zoom>
          </Col>
        })}
      </Row>
    </>
  );
};
export default UpcomingEvent;
