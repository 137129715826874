/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import Dashboardheadernft from '../directives/dashboardheadernft';
import Dashboardsidebarnft from '../directives/dashboardsidebarnft';

import { displayNftUsedinRigAction, showRigsForUserAction, deleteRigMasterAction } from '../Action/user.action';
import config from '../coreFIles/config';
import { Row, Col, Modal } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';
import CopyToClipboard from 'react-copy-to-clipboard';
import '.././component/createrig.css';

const CreateRig = () => {
    const [toggleSet, settoggleSet] = useState(1);
    const [rigBox, setRigBox] = useState([]);
    const [rigBoxX, setRigBoxX] = useState([]);
    const [rigBoxY, setRigBoxY] = useState([]);
    const [rigBoxZ, setRigBoxZ] = useState([]);
    const [EmptyRig, setEmptyRig] = useState(false)

    const [showData, setShowData] = useState([])
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = async (data) => {
        setShow(true);
        let res = await displayNftUsedinRigAction(data.nft_category_id);
        setShowData(res.data)
    }
    useEffect(() => {
        showRigsForUserAPI();
    }, []);

    const handleShow1 = () => {
        setShow1(true);
    }
    const handleClose1 = () => {
        setShow1(false);
    }



    const showRigsForUserAPI = async () => {
        let res = await showRigsForUserAction();
        if (res.success) {
            setEmptyRig(false)
            setRigBox(res.data)
            setRigBoxX(res.data.filter(item => item.nft_category_id == 1))
            setRigBoxY(res.data.filter(item => item.nft_category_id == 2))
            setRigBoxZ((res.data.filter(item => item.nft_category_id == 3)))
        } else if (res.success === false) {
            setEmptyRig(true)
        }
    }

    const toggleManage = (data) => {
        settoggleSet(data)
    }


    const copieBtn = async () => {
        toast.success(`Copied!!`);
    }
    const deleteRig = async (data) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Dis-Assemble this Rig!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, Dis-Assemble it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await deleteRigMasterAction({ rig_master_id: data });
                if (res.success) {
                    Swal.fire("Dis-Assembled!", res.msg, "success");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    Swal.fire("Failed to Dis-Assemble Rig!", res.msg, "error");
                }
            }
        });
    }

    return (
        <>
            <div className={`page-wrapper${toggleSet === 1 ? '' : ' toggled'}`}>
                <Dashboardsidebarnft />
                <div className="main-container">
                    <Dashboardheadernft clickToggle={toggleManage} />
                    <div className="content-wrapper-scroll">


                        <div className="content-wrapper container mymysterybox">

                            <div className='Nftlist'>
                                <Row className='mt-4'>
                                    <Col lg={12} className='mt-2  pl-2 pr-2'>
                                        <Row className='align-items-center '>
                                            <Col lg={8} className='mt-2'>
                                                <h4 className='mb-0'> {EmptyRig == true ? "Create Rig" : "My Rig"}</h4>
                                            </Col>
                                            <Col lg={4} className='mt-2 mb-2 text-right'>
                                                {EmptyRig == true ? "" : <button className='btn btn-rounded btn-primary'
                                                    onClick={() => window.location.href = `${config.baseUrl}rig`
                                                }
                                                    // onClick={() =>
                                                    //     handleShow1()
                                                    // }
                                                    >Assemble Rig</button>
                                                }
                                            </Col>
                                            <Col lg={12}>
                                                <hr className='m-0' />
                                            </Col>

                                        </Row>
                                    </Col>
                                    
                                    <Col lg={12} className='mt-2 pl-2 pr-2'>
                                        <Row>
                                            {EmptyRig == true ? <p className='mt-5 d-flex justify-content-center'><button className='btn btn-rounded btn-primary'
                                             onClick={() => window.location.href = `${config.baseUrl}rig`}
                                            //  onClick={() =>
                                            //     handleShow1()
                                            // }
                                            >Assemble Rig</button></p> : ""}
                                            {
                                                rigBox.length > 0 ? <>
                                                    {
                                                        rigBox.map((item) =>
                                                        (
                                                            <Col lg={4}>
                                                                <div className="cardlist2" >
                                                                    <a onClick={() => handleShow(item)}>
                                                                        <div className='text-center cardimg'>
                                                                            <video width="100%" autoPlay muted playsInline loop >
                                                                                <source src={`${config.ipfsurl +
                                                                                    item.rig_image
                                                                                    }`} type="video/mp4" />
                                                                                <source src={`${config.ipfsurl +
                                                                                    item.rig_image
                                                                                    }`} type="video/ogg" />
                                                                                Your browser does not support the video tag.
                                                                            </video>
                                                                        </div>
                                                                    </a>
                                                                    <div className="cardlistbox">
                                                                        <div className="card-body pb-0">
                                                                            <div className="row  justify-content-center">
                                                                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center ">
                                                                                    <h4 className="card-title">{item.name}</h4>
                                                                                </div>
                                                                            </div>
                                                                            <div className='row pt-3 pb-1' style={{ background: "" }}>

                                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-5 mb-2 ">
                                                                                    <small className='text-uppercase'>HASH</small>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-7 mb-2 text-right">
                                                                                    <small>{item.rig_hash.slice(0, 3) + "....." + item.rig_hash.slice(-3)}&nbsp;
                                                                                        <CopyToClipboard text={item.rig_hash}>
                                                                                            <span title="Click to Copy" className="mr-copylink" id="token-buy-button" onClick={copieBtn} style={{ cursor: "pointer", color: 'rgb(157 81 255)' }}>
                                                                                                <i className="fa fa-clone "></i></span></CopyToClipboard>
                                                                                    </small>
                                                                                </div>

                                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-5  mb-2">
                                                                                    <small className='text-uppercase'>Date</small>
                                                                                </div>
                                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-7 text-right mb-2">
                                                                                    <small>{item.datetime.slice(0, 10)}</small>
                                                                                </div>
                                                                                <div className="col-lg-7 col-md-7 col-sm-7 col-7  mb-2">
                                                                                    <small className='text-uppercase'>TOTAL HASH POWER</small>
                                                                                </div>
                                                                                <div className="col-lg-5 col-md-5 col-sm-5 col-5 text-right mb-2">
                                                                                    <small>{item.rig_hash_power}</small>
                                                                                </div>
                                                                            </div>
                                                                            <button className='btn btn-outline-secondary btn-rounded w-100 mt-1 mb-3' onClick={() => deleteRig(item.rig_master_id)}>Dis-Assemble Rig</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        ))
                                                    }
                                                </> : ""
                                            }

                                         
                                        </Row>
                                    </Col>

                                    <Col className='mt-5 pl-2 pr-2'>
                                    <div className='text-center rigcreation'>
                                    Step into the World of Rig Creation: <a href={`${config.baseUrl}how_to_createrig`}>Click on the link</a> to know Rig creation process and its advantage. 
                                    </div>
                                    </Col>


                                    <Modal show={show} className='Nftmodal' onHide={handleClose} size="lg">
                                        <Modal.Header closeButton>
                                            <Modal.Title className='text-white'>Rig Detail</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='pb-2'>
                                            <Row>
                                                {showData.map((item) => (
                                                    <Col lg={4} md={6} className='mb-3'>
                                                        <div className='nft_list'>

                                                            <h5 className="mb-0 pt-1">{item.type_name} ({item.name})</h5>
                                                            <video
                                                                width="100%"
                                                                autoPlay
                                                                muted
                                                                playsInline
                                                                loop
                                                            >
                                                                <source
                                                                    src={`${config.ipfsurl +
                                                                        item.image
                                                                        }`}
                                                                    type="video/mp4"
                                                                />
                                                                <source
                                                                    src={`${config.ipfsurl +
                                                                        item.image
                                                                        }`}
                                                                    type="video/ogg"
                                                                />
                                                                Your browser does not support the
                                                                video tag.
                                                            </video>
                                                            <ul>
                                                                <li>Quantity</li>
                                                                <li><p>{item.rig_quantity}</p></li>
                                                            </ul>

                                                        </div>

                                                    </Col>
                                                ))}
                                            </Row>
                                        </Modal.Body>

                                    </Modal>

                                    <Modal
                                        show={show1}
                                        onHide={handleClose1}
                                        // backdrop="static"
                                        className="assemble_popup"
                                        centered
                                    >
                                        {/* <Modal.Header ></Modal.Header> */}
                                        <Modal.Body closeButton className="btn-rounded">
                                            <Row className="justify-content-center">
                                                <Col lg={8}>
                                                    <div className="text-center mb-3">
                                                        <div
                                                            className="text-uppercase text-center"
                                                            style={{ fontSize: "1.25rem" }}>
                                                                <div className='position-relative'>
                                                                    <div className="cog_box"></div>
                                                                    <div className="cog_box1"></div>
                                                                    <div className="cog_box2"></div>
                                                                    <div className="cog_box4"></div>
                                                                </div>
                                                            <div className='mb-2' style={{textShadow: "1px 2px 0px #732d94",fontWeight: "600"}}>Hold Tight</div> <p className='font-inherit'> We're Revamping Rig Creation to Serve You Better! </p>
                                                        </div>

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Modal.Body>
                                    </Modal>


                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default CreateRig;