import React, { useEffect, useState } from 'react';
import config from '../../../coreFIles/config';
import Dotcircle1 from '../Icon/dotcircle.svg'
import "../css/dotcircle.css"





const Dotcircle = () => {


  return (

    <>
    <img src={Dotcircle1} width={`35px`} />
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="47"
        viewBox="0 0 48 47"
        fill="none"
      >
        <circle
          cx="24"
          cy="23.5"
          r="23.5"
          fill="#646464"
          fill-opacity="0.34"
        />
        <path
          d="M31.8349 33.3025C37.2234 29.0007 38.1043 21.1452 33.8025 15.7567C29.5008 10.3682 21.6452 9.48727 16.2567 13.7891C10.8682 18.0908 9.98728 25.9464 14.2891 31.3349C18.5909 36.7234 26.4464 37.6043 31.8349 33.3025Z"
          fill="url(#paint0_radial_355_774)"
        />
        <defs>
          <radialGradient
            id="paint0_radial_355_774"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(28.3848 19.8763) rotate(-167.431) scale(17.2772)"
          >
            <stop stop-color="#12B7E4" />
            <stop offset="0.4" stop-color="#1877B8" />
            <stop offset="0.69" stop-color="#1C4F9C" />
            <stop offset="0.83" stop-color="#782F8E" />
            <stop offset="0.95" stop-color="#BD1784" />
            <stop offset="1" stop-color="#D90E81" />
          </radialGradient>
        </defs>
      </svg> */}

    </>
  )

}
export default Dotcircle;