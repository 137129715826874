import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import config from "../coreFIles/config";

export default function Error() {
    return (
        <>
            <Container style={{ height: '100vh' }}>
                <div className="text-center h-100 d-flex align-items-center justify-content-center">
                    <div>
                        <h1 style={{ fontSize: '20rem' }} className="error_heading">404</h1>
                        <h2 className="mb-4">Page Not Found!</h2>
                        <p>We're sorry, the page you requested could not be found. Please go back to the homepage.</p>

                        <Link to={`${config.baseUrl}`}>
                            <Button variant="primary" className="mt-4">GO HOME</Button>
                        </Link>
                    </div>
                </div>
            </Container>
        </>
    )
}