import Cookies from "js-cookie";
import config from '../coreFIles/config'

export const logout =  () => {
    Cookies.remove('loginType');
    Cookies.remove('loginType', { domain: 'mrmint.io' });
    Cookies.remove('loginType', { domain: 'nft.mrmint.io' });
    Cookies.remove('loginSuccessMrMint');
    Cookies.remove('loginSuccessMrMint', { domain: 'mrmint.io' });
    Cookies.remove('loginSuccessMrMint', { domain: 'nft.mrmint.io' });
    setTimeout(() => {
        window.location.href = `${config.baseUrl}`
    });
  }

  